.spn{
    color: red;
}

.frmErrors{
    color : red;
    text-align: start;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    text-transform: none !important;
    font-size: 13px;
}

.file-link{
    color: #007bff !important;
    text-decoration: none !important;
    background-color: transparent;
}