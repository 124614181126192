.p-column-filter-row .p-column-filter-element {
  width: 100% !important;
}

.p-inputtext,
.p-button {
  padding: 0.5rem 0.5rem !important;
}
.file-link{
  color: #007bff !important;
  text-decoration: none !important;
  background-color: transparent;
}