.spn {
  color: red;
}

.frmErrors {
  color: red;
  text-align: start;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  text-transform: none !important;
  font-size: 13px;
}
// #middleLayout{
//     height:550px;
//     overflow: auto;
// }

.p-dropdown {
  display: flex !important;
}

.middleLayout {
  .divContainer {
    font-size: 14px;
  }
}
