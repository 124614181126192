.header{
   // align-content: center;
   border : none;
   border: 1px solid rgba(255,255,255,0.2);
   backdrop-filter: blur(5px);
   -webkit-transition : all 0.2s ease-in-out;
   transition : all 0.2s ease-in-out;
   box-shadow : 2px 2px 4px rgba(0,0,0,0.4);
   background: transparent;
   height : 10vh;
  //  border-radius:15px;
   background-color: #2a3a42;
   display: flex;
   padding: 10px;
   width:100%;
}

.divSpace{
  height : 3vh; 
}

.linkText{
    margin-left: 20px;
    color:#fff;
    font-size: 18px;
    text-decoration:none;
}

.btnLgout{
    color : #fff; 
}

.btnLgout:hover {
    background: rgba(255,255,255,0.1);
    box-shadow : 4px 4px 60px 8px rgba(0,0,0,0.2);
  
  }

.divFirst
{
  width:98%;
  display:flex;
}

.divSecond{
width:2%;
}

a{
  color:blue !important;
  text-decoration:none !important;
}

.bg-dark-header {
  background-color: #2a3a42 !important;
  --bs-bg-opacity: 1;
}

.headerBarButton {
  height: 70px;
  background-color: #64d96e;
  color: #000;
  cursor: pointer;
}

.headerBarButton:hover {
  color: #000;
  background-color: #55b45e;
}

.headerBarButton a,
.headerBarButton a:hover {
  color: #000;
  text-decoration: none;
}

.nav-link.active {
  color: #fff;
  background-color: #0d6efd;
}
.headerSectionbox{
  background-color: #2a3a42;
  padding: 35px;
  -webkit-box-shadow: 0px 3px 26px -6px rgba(0,0,0,0.27);
  -moz-box-shadow: 0px 3px 26px -6px rgba(0,0,0,0.27);
  box-shadow: 0px 3px 26px -6px rgba(0,0,0,0.27);
}

#headerList{
  float:right;
}
.headerBtn{
color: white!important;
}

* {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 20px;
}

select {
  padding: 8px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selected-option {
  font-weight: bold;
  color: #000;
  background-color: #f2f2f2;
}