h2 {
  color: #03a9f4cf
}



.btnLogin {
  background: transparent;
  padding: 1em;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  color: rgb(21, 19, 19);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  //border-radius: 5000px;
  font-weight: bold;
  font-size: 17px;
  background-color: #03a9f4cf;
  //margin-left:25%;
  margin-top: 10%;
  margin-bottom: 10%;
  //width:300px;
}


.loginLayout {

  width: 50%;
  margin: 10%;
}

#ttecLogo {
  background-image: url("../../assets/images/ttecLogo-black.png");
}

.logo {
  background-image: url("../../assets/images/login_logo.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1050px;
}

.row {
  display: block;
}