.clsLoader{
    top : 50%;
    left: 50%;
    position:absolute;
    font-weight: bold;
    font-size : 16px;
    //color:rgb(171, 207, 171);
}

/* Media Queries */

@media screen and (max-width: 426px) {

.clsLoader{
    left: 22%;
}

}