.footer{
  text-align : center;
  background-color: #2a3a42;
  
  color: white;
  position: fixed;
 
  width: 100%;
bottom: 0;
  line-height: 50px;
  display: block;

}
a{
  color: white !important;
text-decoration: underline !important;

}