/* body{
  //  background : linear-gradient(to right, #3f5efb, #fc466b);
  background-image: url('../assets/images/background.jpg');
   }
 */

.divContainer{
    border : none;
    border: 1px solid rgba(255,255,255,0.2);
    backdrop-filter: blur(5px);
    -webkit-transition : all 0.2s ease-in-out;
    transition : all 0.2s ease-in-out;
    box-shadow : 2px 2px 4px rgba(0,0,0,0.4);
    background: transparent;
    //height : 85vh;
    border-radius:12px;
    padding:10px;
    background-color: rgba(255, 255, 255, .15);
    margin: 0px 15px 0px 15px;
  }