.fp-container{
    position : fixed;
    width:100%;
    height : 100%;
    top:0;
    left:0;
    background : #f8f8f8ad;
    z-index:1000 !important;
}

.fp-container .fp-loader{
    top : 50%;
    left:50%;
  //  z-index:1000 !important;
    position:absolute;
}